import { MeDTO } from 'app/data/api/openapi'

// User types

export enum UserPermission {
  CustomerImpersonation = 'CustomerImpersonation',
  CustomerUpdate = 'CustomerUpdate',
  CustomerViewHidden = 'CustomerViewHidden',
  Developer = 'Developer',
  FinancingCancelContract = 'FinancingCancelContract',
  FinancingCreditScoring = 'FinancingCreditScoring',
  FinancingViewDto = 'FinancingViewDto',
  FinancingView = 'FinancingView',
  FinancingViewHidden = 'FinancingViewHidden',
  FinancingWithdrawApplication = 'FinancingWithdrawApplication',
  PartnerCreate = 'PartnerCreate',
  PartnerUpdate = 'PartnerUpdate',
  PartnerView = 'PartnerView',
  Support = 'Support',
  Unknown = 'Unknown',
}

export type UserPermissions = {
  hasCustomerImpersonationPermission: boolean
  hasCustomerUpdatePermission: boolean
  hasCustomerViewHiddenPermission: boolean
  hasDeveloperPermission: boolean
  hasFinancingCancelContractPermission: boolean
  hasFinancingViewDtoPermission: boolean
  hasFinancingViewPermission: boolean
  hasFinancingViewHiddenPermission: boolean
  hasFinancingWithdrawApplicationPermission: boolean
  hasFinancingCreditScoringPermission: boolean
  hasPartnerCreatePermission: boolean
  hasPartnerListPermission: boolean
  hasPartnerUpdatePermission: boolean
  hasSupportPermission: boolean
}

export type UserDTO = Omit<MeDTO, 'permissions'> & {
  permissions: UserPermission[]
}

export enum UserViewContext {
  Loading = 'Loading',
  InternalDashboard = 'InternalDashboard',
  PartnerPortal = 'PartnerPortal',
}

// User Settings
export enum UserSettingOpenPartnerFinancingMode {
  NewTab = 'New Tab',
  InApp = 'In App',
}

export enum UserSettingsAppMode {
  Live = 'Live',
  Beta = 'Beta',
}

export enum UserSettingsViewOption {
  Option1 = 'option-1',
  Option2 = 'option-2',
  Option3 = 'option-3',
  Option4 = 'option-4',
}

export type UserSettings = {
  appMode: UserSettingsAppMode
  appModeBetaViewOption: UserSettingsViewOption
  financingDetailAppMode: UserSettingsAppMode
  isBetaMode: boolean
  isFinancingDetailBetaMode: boolean
  openPartnerFinancingMode: UserSettingOpenPartnerFinancingMode
}
