import { useDispatch, useSelector } from 'react-redux'
import { appActions, appSelectors } from './redux/appState.redux'
import { useQueryClient } from '@tanstack/react-query'
import { UserDTO, UserSettings, UserSettingsAppMode, UserViewContext } from 'app/types/user.types'
import { AppQueryKey } from './queries/app.queries'

/*
 * User data
 */

export const useUser = () => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData<UserDTO>(AppQueryKey.User())

  return {
    user: user!,
  }
}

/*
 * User permissions
 */

export const useUserPermissions = () => {
  const dispatch = useDispatch()
  const actions = appActions(dispatch)
  const userPermissions = useSelector(appSelectors.userPermissions)

  return {
    ...userPermissions,
    setUserPermissions: actions.setUserPermissions,
  }
}

/*
 * User settings data
 */

export const useUserSettings = () => {
  const dispatch = useDispatch()
  const actions = appActions(dispatch)
  const userSettings = useSelector(appSelectors.userSettings)
  const userPermissions = useSelector(appSelectors.userPermissions)

  return {
    userSettings: {
      appMode: userPermissions.hasFinancingCreditScoringPermission
        ? userSettings.appMode
        : UserSettingsAppMode.Live,
      appModeBetaViewOption: userSettings.appModeBetaViewOption,
      financingDetailAppMode: userPermissions.hasFinancingCreditScoringPermission
        ? userSettings.financingDetailAppMode
        : UserSettingsAppMode.Live,
      isBetaMode: userSettings.appMode === UserSettingsAppMode.Beta,
      isFinancingDetailBetaMode: userSettings.financingDetailAppMode === UserSettingsAppMode.Beta,
      openPartnerFinancingMode: userSettings.openPartnerFinancingMode,
    },
    setUserSettings: (
      updatedUserSettings: Omit<UserSettings, 'isBetaMode' | 'isFinancingDetailBetaMode'>,
    ) => {
      const appMode = userPermissions.hasFinancingCreditScoringPermission
        ? updatedUserSettings.appMode
        : UserSettingsAppMode.Live

      const financingDetailAppMode = userPermissions.hasFinancingCreditScoringPermission
        ? updatedUserSettings.financingDetailAppMode
        : UserSettingsAppMode.Live

      return actions.setUserSettings({
        appMode,
        appModeBetaViewOption: updatedUserSettings.appModeBetaViewOption,
        financingDetailAppMode,
        isBetaMode: appMode === UserSettingsAppMode.Beta,
        isFinancingDetailBetaMode: financingDetailAppMode === UserSettingsAppMode.Beta,
        openPartnerFinancingMode: updatedUserSettings.openPartnerFinancingMode,
      })
    },
  }
}

/*
 * User view context data
 */

export const useUserViewContext = () => {
  const dispatch = useDispatch()
  const actions = appActions(dispatch)
  const userViewContext = useSelector(appSelectors.userViewContext)

  return {
    userViewContext,
    setUserViewContext: actions.setUserViewContext,
  }
}
